import Rails from "@rails/ujs";
import modal from "../components/modal";
import button from "../components/button";
import flash from "../components/flash";
import nav from "../components/nav";
import botVisitDetection from "../components/bot_visit_detection";
import "../stylesheets/application.scss";

if (!window._rails_loaded) Rails.start();

document.addEventListener("DOMContentLoaded", function () {
  modal.register();
  button.register();
  flash.register();
  nav.register();
  botVisitDetection.register();
});
