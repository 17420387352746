const nav = {
  register() {
    const openButton = document.querySelector("[data-mobile-nav-open]");
    const closeButton = document.querySelector("[data-mobile-nav-close]");
    const navButton = document.querySelector(".mobile-navigation-button");
    const navigationPanel = document.querySelector(".navigation");

    if (openButton) {
      openButton.addEventListener("click", () => {
        navigationPanel.classList.add("is-active");
        openButton.classList.remove("is-active");
        closeButton.classList.add("is-active");
        navButton.classList.add("is-fixed");
      });
    }

    if (closeButton) {
      closeButton.addEventListener("click", () => {
        navigationPanel.classList.remove("is-active");
        closeButton.classList.remove("is-active");
        openButton.classList.add("is-active");
        navButton.classList.remove("is-fixed");
      });
    }
  },
};

export default nav;
