const button = {
  register() {
    document.querySelectorAll("form").forEach(form => {
      form.removeEventListener("ajax:send", this.handleStart.bind(this));
      form.removeEventListener("ajax:complete", this.handleComplete.bind(this));

      form.addEventListener("ajax:send", this.handleStart.bind(this));
      form.addEventListener("ajax:complete", this.handleComplete.bind(this));
    });
  },

  handleStart(e) {
    const button = e.target.querySelector(`button[type="submit"]`);
    if (button) button.classList.add("is-loading");
  },

  handleComplete(e) {
    const button = e.target.querySelector(`button[type="submit"]`);
    if (button) button.classList.remove("is-loading");
    this.register();
  }
};

export default button;
