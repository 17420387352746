const flash = {
  register() {
    document.querySelectorAll(".flash").forEach(elem => {
      elem.addEventListener("click", elem.remove);
    });

    window.setFlash = function({ type, message }) {
      if (!type) type = "notice";
      const flash = document.createElement("div");
      flash.classList.add("flash", `is-${type}`);
      flash.appendChild(document.createTextNode(message));

      document.querySelector("body").appendChild(flash);

      flash.addEventListener("click", flash.remove);
    };
  }
};

export default flash;
