const modal = {
  register() {
    document.querySelectorAll("[data-modal]").forEach(modalElement => {
      const modalId = modalElement.dataset.modal;
      const triggerElements = document.querySelectorAll(
        `[data-modal-trigger=${modalId}]`
      );
      const background = modalElement.querySelector(".modal-background");
      const modalClose = modalElement.querySelector(".modal-close-button");
      const submitElement = modalElement.querySelector("[submit]");

      triggerElements.forEach(triggerElement => {
        triggerElement.addEventListener("click", () => {
          modalElement.classList.add("is-active");
          document.querySelector("html").classList.add("has-open-modal");
        });
      });

      if (modalClose) {
        modalClose.addEventListener("click", () => {
          modalElement.classList.add("is-fading-out");
          document.querySelector("html").classList.remove("has-open-modal");
        });
      }

      if (submitElement) {
        submitElement.addEventListener("click", () => {
          submitElement.classList.add("is-loading");
        });
      }

      background.addEventListener("click", () => {
        modalElement.classList.add("is-fading-out");
        document.querySelector("html").classList.remove("has-open-modal");
      });

      modalElement.addEventListener("animationend", e => {
        if (e.animationName === "fadeout") {
          modalElement.classList.remove("is-active", "is-fading-out");
        }
      });
    });
  }
};

export default modal;
