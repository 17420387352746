import Rails from "@rails/ujs";
import fingerPrintScanner from "fpscanner";
import fpCollect from "./fp_collect";

export default {
  register() {
    if (!window.visitId) return;

    fpCollect.generateFingerprint().then((fingerPrint) => {
      const results = fingerPrintScanner.analyseFingerprint(fingerPrint);
      const humanScore = this.determineHumanScore(results);
      console.log("Human score:", humanScore);
      this.updateVisit(humanScore);
    });
  },

  determineHumanScore(results) {
    return Object.values(results)
      .map((r) => r.consistent)
      .reduce((sum, x) => sum + x);
  },

  updateVisit(humanScore) {
    Rails.ajax({
      url: `/feedback/${window.visitId}`,
      type: "PUT",
      data: `score=${humanScore}`,
      success: function (data) {
        console.log(data);
      },
      error: function (data) {
        console.log(data);
      },
    });
  },
};
